import rolePermissionService from "@services/rolePermissionService";
import affectationService from "@services/affectationService";

export default {
    data() {
        return {
            permissionList: [],
            currentUser: null,
            userParams: null,
            roleLoading: false,
            permissionConst: {
                forecast: {
                    edit: "FORECAST_EDIT",                                // droit de créer ou modifier une fiche de prévision
                    editOther: "FORECAST_EDIT_OTHER",                     // droit de créer ou modifier une fiche de prévision d'un CEI différent
                    manage: "FORECAST_MANAGE",                            // droit de supprimer une fiche de prévision
                    download: "FORECAST_DOWNLOAD",                            // droit de télécharger une fiche de prévision
                    view: "FORECAST_VIEW",                                // droit de voir une fiche de prévision non validée, non archivée
                    viewValidated: "FORECAST_VIEW_VALIDATED",             // droit de voir une fiche de prévision validée
                    viewArchived: "FORECAST_VIEW_ARCHIVED",               // droit de voir une fiche de prévision archivée
                    viewOther: "FORECAST_VIEW_OTHER",                     // droit de voir une fiche de prévision non validée, non archivée d'un CEI différent
                    viewValidatedOther: "FORECAST_VIEW_VALIDATED_OTHER",  // droit de voir une fiche de prévision validée d'un CEI différent
                    viewArchivedOther: "FORECAST_VIEW_ARCHIVED_OTHER",    // droit de voir une fiche de prévision archivée d'un CEI différent
                    comment: "FORECAST_COMMENT",                          // droit de commenter une fiche de prévision
                },
                link: {
                    edit: "LINK_EDIT",                                    // droit de créer ou modifier une fiche de liaison
                    editOther: "LINK_EDIT_OTHER",                         // droit de créer ou modifier une fiche de liaison d'un CEI différent
                    manage: "LINK_MANAGE",                                // droit de supprimer une fiche de liaison
                    download: "LINK_DOWNLOAD",                            // droit de télécharger une fiche de liaison
                    view: "LINK_VIEW",                                    // droit de voir une fiche de liaison non validée, non archivée
                    viewValidated: "LINK_VIEW_VALIDATED",                 // droit de voir une fiche de liaison validée
                    viewArchived: "LINK_VIEW_ARCHIVED",                   // droit de voir une fiche de liaison archivée
                    viewOther: "LINK_VIEW_OTHER",                         // droit de voir une fiche de liaison non validée, non archivée d'un CEI différent
                    viewValidatedOther: "LINK_VIEW_VALIDATED_OTHER",      // droit de voir une fiche de liaison validée d'un CEI différent
                    viewArchivedOther: "LINK_VIEW_ARCHIVED_OTHER",        // droit de voir une fiche de liaison archivée d'un CEI différent
                },
                classicDailyOverview: {
                    view: "CLASSIC_DAILY_OVERVIEW_VIEW",              // droit de voir les synthèses quotidiennes classiques non archivées
                    archived: "CLASSIC_DAILY_OVERVIEW_VIEW_ARCHIVED",      // droit de voir les synthèses quotidiennes classiques archivées
                },
                modernDailyOverview: {
                    view: "MODERN_DAILY_OVERVIEW_VIEW",               // droit de voir les synthèses quotidiennes modernes non archivées
                    archived: "MODERN_DAILY_OVERVIEW_VIEW_ARCHIVED",       // droit de voir les synthèses quotidiennes modernes archivées
                },
                weeklyOverview: {
                    view: "WEEKLY_OVERVIEW_VIEW",                     // droit de voir les synthèses hebdomadaires non archivées
                    archived: "WEEKLY_OVERVIEW_VIEW_ARCHIVED",             // droit de voir les synthèses hebdomadaires archivées
                },
                healthcareReview: {
                    edit: "HEALTHCARE_REVIEW_EDIT",                   // droit de modifier les bilans de l'organisation des conditions de travail des personnels
                    manage: "HEALTHCARE_REVIEW_MANAGE",               // droit de supprimer les bilans de l'organisation des conditions de travail des personnels
                    view: "HEALTHCARE_REVIEW_VIEW",                   // droit de voir les bilans de l'organisation des conditions de travail des personnels
                    viewArchived: "HEALTHCARE_REVIEW_VIEW_ARCHIVED",  // droit de voir les bilans de l'organisation des conditions de travail des personnels archivés
                },
                circuitReview: {
                    edit: "CIRCUIT_REVIEW_EDIT",                      // droit de modifier les bilans de circuits
                    manage: "CIRCUIT_REVIEW_MANAGE",                  // droit de supprimer les bilans de circuits
                    view: "CIRCUIT_REVIEW_VIEW",                      // droit de voir les bilans de circuits
                    viewArchived: "CIRCUIT_REVIEW_VIEW_ARCHIVED",     // droit de voir les bilans de circuits archivés
                },
                crisisReview: {
                    edit: "CRISIS_REVIEW_EDIT",                       // droit de modifier les bilans de crises
                    manage: "CRISIS_REVIEW_MANAGE",                   // droit de supprimer les bilans de crises
                    view: "CRISIS_REVIEW_VIEW",                       // droit de voir les bilans de crises
                    viewArchived: "CRISIS_REVIEW_VIEW_ARCHIVED",      // droit de voir les bilans de crises archivés
                },
                formationReview: {
                    edit: "FORMATION_REVIEW_EDIT",                    // droit de modifier les bilans de formation
                    manage: "FORMATION_REVIEW_MANAGE",                // droit de supprimer les bilans de formation
                    view: "FORMATION_REVIEW_VIEW",                    // droit de voir les bilans de formation
                    viewArchived: "FORMATION_REVIEW_VIEW_ARCHIVED",   // droit de voir les bilans de formation archivés
                },
                equipmentReview: {
                    edit: "EQUIPMENT_REVIEW_EDIT",                    // droit de modifier les bilans de formation
                    manage: "EQUIPMENT_REVIEW_MANAGE",                // droit de supprimer les bilans de formation
                    view: "EQUIPMENT_REVIEW_VIEW",                    // droit de voir les bilans de formation
                    viewArchived: "EQUIPMENT_REVIEW_VIEW_ARCHIVED",   // droit de voir les bilans de formation archivés
                },
                bilanVH: {
                    download: "BILAN_VH_DOWNLOAD",                    // droit de télécharger les bilans VH
                },
                admin: {
                    edit: "ADMIN_EDIT",                               // droit de modifier les paramètres d'administration
                    manage: "ADMIN_MANAGE",                           // droit de supprimer des données des paramètres d'administration
                    view: "ADMIN_VIEW",                                // droit de voir les paramètres d'administration
                },
                intervention: {
                    delete: "INTERVENTION_DELETE",     // droit de supprimer une fiche d'intervention
                    edit: "INTERVENTION_EDIT",              // droit de modifier une fiche d'intervention
                    download: "INTERVENTION_DOWNLOAD",              // droit de télécharger une fiche d'intervention
                },
                ceiAgent: {
                    manage: "CEI_AGENTS_MANAGE",
                },
                actualite: {
                    manage: "ACTUALITE_MANAGE",
                },
            },
            affectations: [],
            userCeiList: [],
            userUerList: [],
            userAgerList: []
        }
    },

    created() {
        this.roleLoading = true;
        this.currentUser = this.$store.state.userInformation;
        this.userParams = this.$store.state.userParams;

        this.ceiRequiredForGroups = ['DIRECTION_CEI', 'RI_CEI'];
        this.uerRequiredForGroups = ['DIRECTION_UER'];
        this.agerRequiredForGroups = ['DIRECTION_AGER', 'CADRE_N1_AGER', 'UGF_AGER','AGENT','BAGR_AGER'];

        this.forecastPerimeterByGroup = {
            'N2_DIRIF': {
                cei: false,
                uer: false,
                ager: false
            },
            'CADRE_N1_STT': {
                cei: false,
                uer: false,
                ager: false
            },
            'CADRE_N1_AGER': {
                cei: false,
                uer: false,
                ager: true
            },
            'BAGR_AGER': {
                cei: false,
                uer: false,
                ager: true
            },
            'DIRECTION_AGER': {
                cei: false,
                uer: false,
                ager: true
            },
            'DIRECTION_UER': {
                cei: false,
                uer: true,
                ager: true
            },
            'RI_CEI': {
                cei: true,
                uer: true,
                ager: true
            }
        }

        this.linkPerimeterByGroup = {
            'N2_DIRIF': {
                cei: false,
                uer: false,
                ager: false
            },
            'CADRE_N1_STT': {
                cei: false,
                uer: false,
                ager: false
            },
            'CADRE_N1_AGER': {
                cei: false,
                uer: false,
                ager: true
            },
            'BAGR_AGER': {
                cei: false,
                uer: false,
                ager: true
            },
            'DIRECTION_AGER': {
                cei: false,
                uer: false,
                ager: true
            },
            'DIRECTION_UER': {
                cei: false,
                uer: true,
                ager: true
            },
            'RI_CEI': {
                cei: true,
                uer: true,
                ager: true
            }
        }

        this.interventionPerimeterByGroup = {
            'N2_DIRIF': {
                cei: false,
                uer: false,
                ager: false
            },
            'CADRE_N1_STT': {
                cei: false,
                uer: false,
                ager: false
            },
            'CADRE_N1_AGER': {
                cei: false,
                uer: false,
                ager: true
            },
            'BAGR_AGER': {
                cei: false,
                uer: false,
                ager: true
            },
            'DIRECTION_AGER': {
                cei: false,
                uer: false,
                ager: true
            },
            'DIRECTION_UER': {
                cei: false,
                uer: true,
                ager: true
            },
            'RI_CEI': {
                cei: true,
                uer: true,
                ager: true
            }
        }

        this.bilanPerimeterByGroup = {
            'DIRECTION_AGER': {
                cei: false,
                uer: false,
                ager: true
            },
            'DIRECTION_UER': {
                cei: false,
                uer: true,
                ager: true
            },
            'MIPOLEX': {
                cei: false,
                uer: false,
                ager: false
            }
        }

        let p1 = rolePermissionService.findAllByCurrentUser();
        p1.then(data => this.permissionList = data);

        let p2 = affectationService.findAllByUserId(this.currentUser.userId).then(data => {
            this.affectations = data.affectations;
            this.userCeiList = data.ceiList;
            this.userUerList = data.uerList;
            this.userAgerList = data.agerList;
        });

        Promise.all([p1, p2]).then(() => this.roleLoading = false);
    },

    methods: {
        isInAger(agerId) {
          for (let ager of this.userAgerList) {
            if (ager.id === agerId) return true;
          }
        },
        isInUer(uerId) {
            for (let uer of this.userUerList) {
                if (uer.id === uerId) return true;
            }
        },
        isInCei(ceiId) {
            for (let cei of this.userCeiList) {
                if (cei.id === ceiId) return true;
            }
        },
        getCeiIds() {
            return this.userCeiList.map(cei => cei.id);
        },
        getCeis() {
          return this.userCeiList;
        },
        hasPermission(permission) {
            return this.permissionList.includes(permission);
        },
        isUserGroupAdmin() {
            return this.currentUser.userGroupTechNames.includes('ADMIN');
        },
        isUserGroupDirection() {
            return this.currentUser.userGroupTechNames.includes('DIRECTION');
        },
        isUserGroupDirectionAGER() {
            return this.currentUser.userGroupTechNames.includes('DIRECTION_AGER');
        },
        isUserGroupCadreN1AGER() {
            return this.currentUser.userGroupTechNames.includes('CADRE_N1_AGER');
        },
        isUserGroupBagrAger() {
            return this.currentUser.userGroupTechNames.includes('BAGR_AGER');
        },
        isUserGroupAdminFichiers() {
            return this.currentUser.userGroupTechNames.includes('ADMIN_FICHIERS');
        },
        isUserGroupUgfAGER() {
            return this.currentUser.userGroupTechNames.includes('UGF_AGER');
        },
        isUserGroupDirectionUER() {
            return this.currentUser.userGroupTechNames.includes('DIRECTION_UER');
        },
        isUserGroupDirectionCEI() {
            return this.currentUser.userGroupTechNames.includes('DIRECTION_CEI');
        },
        isUserGroupRiCEI() {
            return this.currentUser.userGroupTechNames.includes('RI_CEI');
        },
        isUserGroupDirectionSTT() {
            return this.currentUser.userGroupTechNames.includes('DIRECTION_STT');
        },
        isUserGroupSTT() {
            return this.currentUser.userGroupTechNames.includes('STT');
        },
        isUserGroupMiPOLEX() {
            return this.currentUser.userGroupTechNames.includes('MIPOLEX');
        },
        isUserGroupSGD() {
            return this.currentUser.userGroupTechNames.includes('SGD');
        },
        isUserGroupSGPR() {
            return this.currentUser.userGroupTechNames.includes('SGPR');
        },
        isUserGroupSMR() {
            return this.currentUser.userGroupTechNames.includes('SMR');
        },
        isUserGroupAgent() {
            return this.currentUser.userGroupTechNames.includes('AGENT');
        },
        isUserGroupPartenaire() {
            return this.currentUser.userGroupTechNames.includes('PARTENAIRE');
        },
        isUserGroupN2Dirif() {
            return this.currentUser.userGroupTechNames.includes('N2_DIRIF');
        },
        isUserGroupN1STT() {
            return this.currentUser.userGroupTechNames.includes('N1_STT');
        },

        isHierarchy() {
            return this.isUserGroupDirectionAGER()
                || this.isUserGroupCadreN1AGER()
                || this.isUserGroupDirectionUER()
                || this.isUserGroupDirectionCEI()
                || this.isUserGroupRiCEI()
                || this.isUserGroupN2Dirif();
        },

        isMyCEI(data) {
            if (this.userCeiList === undefined || this.userCeiList.length === 0) return false;
            for (let cei of this.userCeiList) {
                if (cei.id === data?.ceiId) return true;
            }
            return false;
        },
        isMyUER(data) {
            if (this.userUerList === undefined || this.userUerList.length === 0) return false;
            for (let uer of this.userUerList) {
                if (uer.id === data?.uerId) return true;
            }
            return false;
        },
        isMyAGER(data) {
            if (this.userAgerList === undefined || this.userAgerList.length === 0) return false;
            for (let ager of this.userAgerList) {
                if (ager.id === data?.agerId) return true;
            }
            return false;
        },

        isRelatedToUser(data) {
            return this.isMyCEI(data) || this.isMyUER(data) || this.isMyAGER(data);
        },

        isRelatedToUserAffectations(data) {
          for (let affectation of this.affectations) {
              if (affectation?.ceiId !== null) {
                  if (affectation?.ceiId === data.ceiId) return true;
              } else if (affectation?.uerId !== null) {
                  if (affectation?.uerId === data.uerId) return true;
              } else if (affectation?.agerId !== null) {
                  if (affectation?.agerId === data.agerId) return true;
              } else {
                  return true;
              }
          }
          return false;
        },

        hasAger() {
            return !!this.currentUser.agerId
        },

        //    INTERVENTIONS
        
        canComment(intervention) {
            return this.isUserGroupUgfAGER()
                || this.isUserGroupDirectionCEI()
                || this.isUserGroupCadreN1AGER()
                || this.isUserGroupDirectionUER()
                || this.isUserGroupDirectionAGER()
                || this.isUserGroupAdmin()
                || intervention.userId === this.currentUser.userId;
        },
        canUpdateIntervention(intervention) {
            if(!this.hasPermission(this.permissionConst.intervention.edit)) return false;
            if(!this.isRelatedToUserAffectations(intervention)) return false;
            return this.currentUser.userId === intervention.userId;
        },
        canDownloadIntervention() {
            return this.hasPermission(this.permissionConst.intervention.download);
        },
        canDeleteIntervention() {
            return this.hasPermission(this.permissionConst.intervention.delete)
        },
        canDeleteInterventionWithData(intervention) {
            return this.canDeleteIntervention() &&
                (this.isUserGroupAdmin() || this.currentUser.userId === intervention.userId);
        },
        canCreateIntervention() {
            return this.hasPermission(this.permissionConst.intervention.edit)
        },
        canDeleteInterventions(data) {
            for (let intervention of data) {
                if (!this.canDeleteIntervention(intervention) || !this.isRelatedToUser(intervention)) return false;
            }
            return true;
        },
        
        isReadOnlyInterventions() {
           if (this.isUserGroupDirection()) return true;
           if (this.isUserGroupAgent()) return true;
           if (this.isUserGroupDirectionSTT()) return true;
           if (this.isUserGroupMiPOLEX()) return true;
           if (this.isUserGroupPartenaire()) return true;
           if (this.isUserGroupSGD()) return true;
           if (this.isUserGroupSGPR()) return true;
           if (this.isUserGroupSMR()) return true;
           if (this.isUserGroupSTT()) return true;
           if (this.isUserGroupUgfAGER()) return true;
           
           return false;
       },
        
        //    SHEETS

       canViewAtLeastOneSheet() {
           if (this.canViewForecast()) return true;
           if (this.canViewArchivedForecast()) return true;
           if (this.canViewLink()) return true;
           if (this.canViewArchivedLink()) return true;
           
           return false;
       },

        
        
        // data = fiche de liaison ou prévision
        // NOTE : methode renommée pour éviter les confusions avec les pages vue
        
        canEdit2(data) {
            if (data?.year) return false; // Non modifiable si archivé
            if (data?.dateValidated) return false; // Non modifiable si validé
            
            if (this.isUserGroupRiCEI()) return true;
            if (this.isUserGroupDirectionCEI()) return true;
            if (this.isUserGroupDirectionUER()) return true;
            if (this.isUserGroupDirectionAGER()) return true;
            if (this.isUserGroupCadreN1AGER()) return true;
            if (this.isUserGroupN2Dirif()) return true;
        
            return false;
        },

        
        // data = fiche de liaison ou prévision
        canValidate(data) {
            return this.canEdit2(data);
        },

        isSheetCreator(data) {
            return data?.createdById === this.currentUser.userId;
        },

        // FORECAST

        canViewForecast() {
            if (this.isUserGroupAdmin()) return true;
            if (this.hasPermission(this.permissionConst.forecast.view)) return true;
            if (this.hasPermission(this.permissionConst.forecast.viewOther)) return true;
            return false;
        },
        
        canViewValidatedForecast() {
            if (this.isUserGroupAdmin()) return true;
            if (this.hasPermission(this.permissionConst.forecast.viewValidated)) return true;
            if (this.hasPermission(this.permissionConst.forecast.viewValidatedOther)) return true;
            return false;
        },
        
        canViewArchivedForecast() {
            if (this.isUserGroupAdmin()) return true;
            if (this.hasPermission(this.permissionConst.forecast.viewArchived)) return true;
            if (this.hasPermission(this.permissionConst.forecast.viewArchivedOther)) return true;
            return false;
        },
        
        canViewThisForecast(data) {
            if (this.isUserGroupAdmin()) return true;
            if (this.hasPermission(this.permissionConst.forecast.view) && this.isRelatedToUser(data)) return true;
            if (this.hasPermission(this.permissionConst.forecast.viewOther) && !this.isRelatedToUser(data)) return true;
            return false;
        },
        
        canViewThisValidatedForecast(data) {
            if (this.isUserGroupAdmin()) return true;
            if (this.hasPermission(this.permissionConst.forecast.viewValidated) && this.isRelatedToUser(data)) return true;
            if (this.hasPermission(this.permissionConst.forecast.viewValidatedOther) && !this.isRelatedToUser(data)) return true;
            return false;
        },
        
        canViewThisArchivedForecast(data) {
            if (this.isUserGroupAdmin()) return true;
            if (this.hasPermission(this.permissionConst.forecast.viewArchived) && this.isRelatedToUser(data)) return true;
            if (this.hasPermission(this.permissionConst.forecast.viewArchivedOther) && !this.isRelatedToUser(data)) return true;
            return false;
        },
        
        canOnlyViewArchivedForecasts() {
            if (this.canViewForecast()) return false;
            if (this.canViewValidatedForecast()) return false;
            if (this.canViewArchivedForecast()) return true;
            return false;
        },
        canDeleteForecast() {
            return this.hasPermission(this.permissionConst.forecast.manage);
        },
        canCreateForecast() {
            if (this.isUserGroupN1STT()) return true;
            if (this.isUserGroupAdmin()) return true;
            return this.hasPermission(this.permissionConst.forecast.edit);
        },

        // LINK

        canViewLink() {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.link.view) || this.hasPermission(this.permissionConst.link.viewOther));
        },
        canViewValidatedLink() {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.link.viewValidated) || this.hasPermission(this.permissionConst.link.viewValidatedOther));
        },
        canViewArchivedLink() {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.link.viewArchived) || this.hasPermission(this.permissionConst.link.viewArchivedOther));
        },
        canViewThisLink(data) {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.link.view) && this.isRelatedToUser(data))
                || (this.hasPermission(this.permissionConst.link.viewOther) && !this.isRelatedToUser(data));
        },
        canViewThisValidatedLink(data) {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.link.viewValidated) && this.isRelatedToUser(data))
                || (this.hasPermission(this.permissionConst.link.viewValidatedOther) && !this.isRelatedToUser(data));
        },
        canViewThisArchivedLink(data) {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.link.viewArchived) && this.isRelatedToUser(data))
                || (this.hasPermission(this.permissionConst.link.viewArchivedOther) && !this.isRelatedToUser(data));
        },
        canOnlyViewArchivedLink() {
            return !this.canViewLink()
                && !this.canViewValidatedLink()
                && this.canViewArchivedLink();
        },
        canCreateLink() {
            return this.hasPermission(this.permissionConst.link.edit);
        },
        canDeleteLink() {
            return this.hasPermission(this.permissionConst.link.manage);
        },
        canDownloadLink() {
            return this.hasPermission(this.permissionConst.link.download);
        },

        //    OVERVIEWS

        canViewAtLeastOneOverview() {
            return this.canViewCD() || this.canViewArchivedCD() ||
                this.canViewMD() || this.canViewArchivedMD() ||
                this.canViewW() || this.canViewArchivedW();
        },


        // CLASSIC DAILY OVERVIEW

        canViewCD() {
            return this.hasPermission(this.permissionConst.classicDailyOverview.view);
        },

        canViewArchivedCD() {
            return this.hasPermission(this.permissionConst.classicDailyOverview.archived);
        },

        canOnlyViewArchivedCD() {
            return !this.canViewCD() && this.canViewArchivedCD();
        },

        // MODERN DAILY OVERVIEW

        canViewMD() {
            return this.hasPermission(this.permissionConst.modernDailyOverview.view);
        },

        canViewArchivedMD() {
            return this.hasPermission(this.permissionConst.modernDailyOverview.archived);
        },

        canOnlyViewArchivedMD() {
            return !this.canViewMD() && this.canViewArchivedMD();
        },

        // WEEKLY OVERVIEW

        canViewW() {
            return this.hasPermission(this.permissionConst.weeklyOverview.view);
        },

        canViewArchivedW() {
            return this.hasPermission(this.permissionConst.weeklyOverview.archived);
        },

        canOnlyViewArchivedW() {
            return !this.canViewW() && this.canViewArchivedW();
        },


        //    REVIEWS

        canViewAtLeastOneReview() {
            return this.canViewHealthcare() || this.canViewHealthcareArchived() ||
                this.canViewCircuit() || this.canViewCircuitArchived() ||
                this.canViewCrisis() || this.canViewCrisisArchived() ||
                this.canViewFormation() || this.canViewFormationArchived() ||
                this.canViewEquipment() || this.canViewEquipmentArchived();
        },

        canEditOrDeleteReview(data) {
            if (this.isUserGroupAdmin()) return true;
            if (data.createdById === this.currentUser.userId) return true;
            if (this.isUserGroupMiPOLEX()) return true;
            if (this.isUserGroupDirectionAGER()) if (this.isInAger(data.agerId)) return true;
            if (this.isUserGroupDirectionUER()) if (this.isInUer(data.uerId)) return true;
            if (this.isUserGroupDirectionCEI()) if (this.isInCei(data.ceiId)) return true;
            return false;
        },


        // HEALTHCARE REVIEW

        canViewHealthcare() {
            return this.hasPermission(this.permissionConst.healthcareReview.view);
        },
        canViewHealthcareArchived() {
            return this.hasPermission(this.permissionConst.healthcareReview.viewArchived);
        },
        canDeleteHealthcare() {
            return this.hasPermission(this.permissionConst.healthcareReview.manage);
        },
        canCreateHealthcare() {
            return this.hasPermission(this.permissionConst.healthcareReview.edit);
        },
        canEditHealthcare() {
            return this.hasPermission(this.permissionConst.healthcareReview.edit);
        },

        // CIRCUIT REVIEW

        canViewCircuit() {
            return this.hasPermission(this.permissionConst.circuitReview.view);
        },
        canViewCircuitArchived() {
            return this.hasPermission(this.permissionConst.circuitReview.viewArchived);
        },
        canDeleteCircuit() {
            return this.hasPermission(this.permissionConst.circuitReview.manage);
        },
        canCreateCircuit() {
            return this.hasPermission(this.permissionConst.circuitReview.edit);
        },
        canEditCircuit() {
            return this.hasPermission(this.permissionConst.circuitReview.edit);
        },

        // CRISIS REVIEW

        canViewCrisis() {
            return this.hasPermission(this.permissionConst.crisisReview.view);
        },
        canViewCrisisArchived() {
            return this.hasPermission(this.permissionConst.crisisReview.viewArchived);
        },
        canDeleteCrisis() {
            return this.hasPermission(this.permissionConst.crisisReview.manage);
        },
        canCreateCrisis() {
            return this.hasPermission(this.permissionConst.crisisReview.edit);
        },
        canEditCrisis() {
            return this.hasPermission(this.permissionConst.crisisReview.edit);
        },

        // FORMATION REVIEW

        canViewFormation() {
            return this.hasPermission(this.permissionConst.formationReview.view);
        },
        canViewFormationArchived() {
            return this.hasPermission(this.permissionConst.formationReview.viewArchived);
        },
        canDeleteFormation() {
            return this.hasPermission(this.permissionConst.formationReview.manage);
        },
        canCreateFormation() {
            return this.hasPermission(this.permissionConst.formationReview.edit);
        },
        canEditFormation() {
            return this.hasPermission(this.permissionConst.formationReview.edit);
        },

        // EQUIPMENT REVIEW

        canViewEquipment() {
            return this.hasPermission(this.permissionConst.equipmentReview.view);
        },
        canViewEquipmentArchived() {
            return this.hasPermission(this.permissionConst.equipmentReview.viewArchived);
        },
        canDeleteEquipment() {
            return this.hasPermission(this.permissionConst.equipmentReview.manage);
        },
        canCreateEquipment() {
            return this.hasPermission(this.permissionConst.equipmentReview.edit);
        },
        canEditEquipment() {
            return this.hasPermission(this.permissionConst.equipmentReview.edit);
        },

        canDownloadBilanVH() {
          if (!this.hasPermission(this.permissionConst.bilanVH.download)) return false;
          return this.canViewAtLeastOneReview();
        },

        //    ADMIN

        canViewAdmin() {
            return this.hasPermission(this.permissionConst.admin.view);
        },
        canEditAdmin() {
            return this.hasPermission(this.permissionConst.admin.edit);
        },
        canDeleteAdmin() {
            return this.hasPermission(this.permissionConst.admin.manage);
        },
        showAger(userGroup) {
            if (userGroup && userGroup.techName) switch (userGroup.techName) {
                case 'DIRECTION_AGER':
                    return true;
                case 'CADRE_N1_AGER':
                    return true;
                case 'UGF_AGER':
                    return true;
                case 'DIRECTION_UER':
                    return true;
                case 'DIRECTION_CEI':
                    return true;
                case 'RI_CEI':
                    return true;
                case 'AGENT':
                    return true;
                case 'BAGR_AGER':
                    return true;
                default:
                    return false;
            }
            return false;
        },
        showUer(userGroup) {
            if (userGroup && userGroup.techName) switch (userGroup.techName) {
                case 'DIRECTION_UER':
                    return true;
                case 'DIRECTION_CEI':
                    return true;
                case 'RI_CEI':
                    return true;
                case 'AGENT':
                    return true;
                default:
                    return false;
            }
            return false;
        },
        showCei(userGroup) {
            if (userGroup && userGroup.techName) switch (userGroup.techName) {
                case 'DIRECTION_CEI':
                    return true;
                case 'RI_CEI':
                    return true;
                case 'AGENT':
                    return true;
                default:
                    return false;
            }
            return false;
        },
        
        // CEI AGENT

        canViewCeiUserAdmin() {
            return this.hasPermission(this.permissionConst.ceiAgent.manage);
        },
        canManageCeiUserAdmin() {
            return this.hasPermission(this.permissionConst.ceiAgent.manage);
        },
        
        // ACTUALITE
        
        canManageActualite() {
            return this.hasPermission(this.permissionConst.actualite.manage);
        },
        
        isCeiRequiredForGroup(groupName) {
            return this.ceiRequiredForGroups.includes(groupName);
        },
        isCeiRequiredForGroups(groups) {
            if (groups === undefined || !this.isObjIterable(groups)) return false;
            for (let group of groups) {
                if (this.ceiRequiredForGroups.includes(group.techName)) return true;
            }
            return false;
        },
        isUerRequiredForGroup(groupName) {
            return this.isCeiRequiredForGroup(groupName) || this.uerRequiredForGroups.includes(groupName);
        },
        isUerRequiredForGroups(groups) {
            if (groups === undefined || !this.isObjIterable(groups)) return false;
            if (this.isCeiRequiredForGroups(groups)) return true;
            for (let group of groups) {
                if (this.isUerRequiredForGroup(group.techName)) return true;
            }
            return false;
        },
        isAgerRequiredForGroup(groupName) {
            return this.isUerRequiredForGroup(groupName) || this.agerRequiredForGroups.includes(groupName);
        },
        isAgerRequiredForGroups(groups) {
            if (groups === undefined || !this.isObjIterable(groups)) return false;
            if (this.isUerRequiredForGroups(groups)) return true;
            for (let group of groups) {
                if (this.isAgerRequiredForGroup(group.techName)) return true;
            }
            return false;
        },
        isCeiRequirementsMetForUser(userWithGroups) {
            if (!this.isCeiRequiredForGroups(userWithGroups?.groups)) return true;
            for (let affectation of userWithGroups?.affectationsDTO?.affectations) {
                if (affectation.ceiId !== null) {
                    return true;
                }
            }
            return false;
        },
        isUerRequirementsMetForUser(userWithGroups) {
            if (!this.isUerRequiredForGroups(userWithGroups?.groups)) return true;
            for (let affectation of userWithGroups?.affectationsDTO?.affectations) {
                if (affectation.uerId !== null) {
                    return true;
                }
            }
            return false;
        },
        isAgerRequirementsMetForUser(userWithGroups) {
            if (!this.isAgerRequiredForGroups(userWithGroups?.groups)) return true;
            for (let affectation of userWithGroups?.affectationsDTO?.affectations) {
                if (affectation.agerId !== null) {
                    return true;
                }
            }
            return false;
        },
        isPerimeterRequiredForCurrentUser(item, perimeter) {
            let required;
            for (let group of this.currentUser.userGroupTechNames) {
                if (!Object.keys(item).includes(group)) continue;
                if (!item[group][perimeter]) {
                    return false;
                } else {
                    required = true;
                }
            }
            return required !== undefined ? required : false;
        },
        isInterventionPerimeterRequiredForCurrentUser(perimeter) {
            return this.isPerimeterRequiredForCurrentUser(this.interventionPerimeterByGroup, perimeter);
        },
        isInterventionCeiPerimeterRequiredForCurrentUser() {
            return this.isInterventionPerimeterRequiredForCurrentUser('cei');
        },
        isInterventionUerPerimeterRequiredForCurrentUser() {
            return this.isInterventionPerimeterRequiredForCurrentUser('uer');
        },
        isInterventionAgerPerimeterRequiredForCurrentUser() {
            return this.isInterventionPerimeterRequiredForCurrentUser('ager');
        },
        isForecastPerimeterRequiredForCurrentUser(perimeter) {
            return this.isPerimeterRequiredForCurrentUser(this.forecastPerimeterByGroup, perimeter);
        },
        isForecastCeiPerimeterRequiredForCurrentUser() {
            return this.isForecastPerimeterRequiredForCurrentUser('cei');
        },
        isForecastUerPerimeterRequiredForCurrentUser() {
            return this.isForecastPerimeterRequiredForCurrentUser('uer');
        },
        isForecastAgerPerimeterRequiredForCurrentUser() {
            return this.isForecastPerimeterRequiredForCurrentUser('ager');
        },
        isLinkPerimeterRequiredForCurrentUser(perimeter) {
            return this.isPerimeterRequiredForCurrentUser(this.linkPerimeterByGroup, perimeter);
        },
        isLinkCeiPerimeterRequiredForCurrentUser() {
            return this.isLinkPerimeterRequiredForCurrentUser('cei');
        },
        isLinkUerPerimeterRequiredForCurrentUser() {
            return this.isLinkPerimeterRequiredForCurrentUser('uer');
        },
        isLinkAgerPerimeterRequiredForCurrentUser() {
            return this.isLinkPerimeterRequiredForCurrentUser('ager');
        },
        isBilanPerimeterRequiredForCurrentUser(perimeter) {
            return this.isPerimeterRequiredForCurrentUser(this.bilanPerimeterByGroup, perimeter);
        },
        isBilanCeiPerimeterRequiredForCurrentUser() {
            return this.isBilanPerimeterRequiredForCurrentUser('cei');
        },
        isBilanUerPerimeterRequiredForCurrentUser() {
            return this.isBilanPerimeterRequiredForCurrentUser('uer');
        },
        isBilanAgerPerimeterRequiredForCurrentUser() {
            return this.isBilanPerimeterRequiredForCurrentUser('ager');
        },
        isObjIterable(obj) {
            // checks for null and undefined
            if (obj == null) {
                return false;
            }
            return typeof Object(obj)[Symbol.iterator] === 'function';

        },


        // GESTION DE FICHIERS
        canManageGED() {
            return this.hasPermission("GED_MANAGE");
        }
    }
}